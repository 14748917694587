<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Unmap Terminals</h1>
      <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Download CSV
        </button>
      </div>

      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Terminal ID or Serial Number"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearFilter"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="mb-5 flex mt-20">
        <button
          @click="filterToggle = !filterToggle"
          :class="
            filterToggle ? 'bg-blue-900 text-white' : 'bg-blue-50 text-blue-800'
          "
          class="block uppercase shadow ml-2 text-sm font-bold text-white hover:bg-blue-100 hover:text-blue-800 focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-filter"
            viewBox="0 0 16 16"
            style="display: inline; font-weight: 900"
          >
            <path
              d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          Filter by
        </button>
      </div>
      <div class="mt-4 flex bg-gray-50 p-5" v-if="filterToggle">
        <div class="block">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByParams"
          >
            <option value="">Set Filter Params</option>
            <option value="terminalProvider">Terminal Providers</option>
            <option value="terminalType">Terminal Type</option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'terminalProvider'">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByTerminalProviders"
          >
            <option :value="null" disabled>Select Terminal Provider</option>
            <option
              v-for="providers in terminalProviders"
              :value="providers.name.toLowerCase()"
              :key="providers._id"
            >
              {{ providers.name }}
            </option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'terminalType'">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByTerminalType"
          >
            <option value="">Filter by Terminal Type</option>
            <option value="mpos">mPOS</option>
            <option value="android">Android</option>
            <option value="linux">Linux</option>
          </select>
        </div>
        <div class="block">
          <button
            class="inline-block bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="filterFunc"
          >
            Filter
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl bg-yellow-200 text-dark py-2 px-3 mt-4 rounded-md mr-2"
            @click="clearFilterFunc"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="23"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Phone Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Serial Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Bank
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Provider Name
              </th>

              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Traction Representative
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(terminal, i) in filterUnmappedTerminals" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.terminal_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.assignment.store_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.business_email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.business_phone || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.serial_no || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.bank_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.physical_address || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.terminal_type || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.terminal_provider_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.traction_rep || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="handleUnmapTerminal(terminal)"
                  v-if="terminal.is_assigned_to_merchant"
                  class="block uppercase shadow hover:bg-red-800 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                >
                  Unmap
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="GET_ALL_UNMAPPED_TERMINALS.data.terminals.length > 99"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-check-circle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
            />
          </svg>
          {{ alertDivMssg }}
          <button
            @click="alertDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-800 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      followUpSwitch: null,
      accounts: null,
      dateData: null,
      currentPage: 1,
      totalPages: 0,
      isDisabled: true,
      dateFilter: false,
      searchActive: false,
      filterByParams: "",
      filterByTerminalProviders: "",
      filterByTerminalType: "",
      filterToggle: false,
      alertDiv: false,
      alertDivMssg: "Are you sure you want to unmap this terminal?",
      filterActive: false,
      terminalProviders: [],
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_ALL_UNMAPPED_TERMINALS"]),
    filterUnmappedTerminals() {
      if (this.GET_ALL_UNMAPPED_TERMINALS.data) {
        console.log(this.GET_ALL_UNMAPPED_TERMINALS.data.terminals);
        return this.GET_ALL_UNMAPPED_TERMINALS.data.terminals;
      }
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = false;
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };

    this.fetchUnmappedTerminals();
    this.fetchProviders();
    // this.getterminatypes();
  },
  methods: {
    ...mapActions([
      "FETCH_ALL_UNMAPPED_TERMINALS",
      "GET_ALL_TERMINAL_PROVIDERS",
      "FETCH_FILTERED_UNMAPPED_TERMINALS",
      "DOWNLOAD_ALL_UNMAPPED_TERMINALS",
      "FETCH_PAGINATED_ALL_UNMAPPED_TERMINALS",
      "SEARCH_UNMAP_TERMINAL",
      "FETCH_DATED_TERMINAL_REQUESTS",
      "DOWNLOAD_DATE_TERMINAL_REQUESTS_LOG_CSV",
    ]),
    async fetchUnmappedTerminals() {
      this.isLoading = true;
      let source = this.source;
      try {
        let response = await this.$store.dispatch(
          "FETCH_ALL_UNMAPPED_TERMINALS"
        );
        this.isLoading = false;
        if (response.status) {
          this.isLoading = false;
        } else {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async fetchProviders() {
      this.isLoading = true;
      try {
        let providers = await this.$store.dispatch(
          "GET_ALL_TERMINAL_PROVIDERS"
        );
        if (providers.data) {
          this.terminalProviders = providers.data.items;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async handleUnmapTerminal(terminal) {
      const res = confirm(
        `Are you sure you want to unmap this terminal (${terminal.terminal_id})?`
      );
      if (res) {
        let payload = {
          terminal_id: terminal.terminal_id,
          serial_no: terminal.serial_no,
        };
        this.isLoading = true;
        try {
          let response = await this.$store.dispatch(
            "SET_UNMAP_TERMINALS",
            payload
          );
          this.isLoading = false;
          if (response.status) {
            this.isLoading = false;
            this.alertDiv = true;
            this.alertDivMssg = response.message;

            this.fetchUnmappedTerminals();
          } else {
            alert("Sorry, network error occured.");
          }
        } catch (error) {}
      }
    },
    clearFilter() {
      if (this.search !== "") {
        this.fetchUnmappedTerminals();
        this.search = "";
        this.currentPage = 1;
        this.searchActive = false;
      }
    },
    clearDateFilter() {
      this.fetchUnmappedTerminals();
      this.currentPage = 1;
      this.dateFilter = false;
      // this.resetDate();
    },
    async filterFunc() {
      this.isLoading = true;
      const filterBy = this.filterByParams;
      this.currentPage = 1;
      let filterParams = "";

      if (filterBy === "terminalType") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "terminalProvider") {
        filterParams = this.filterByTerminalProviders;
      }
      this.filterActive = true;
      let payload = { filterBy, filterParams };
      try {
        let res = await this.$store.dispatch(
          "FETCH_FILTERED_UNMAPPED_TERMINALS",
          payload
        );
        this.isLoading = false;
        if (res.data.terminals.length < 1) {
          alert("Could not find any data");
          // this.fetchTerminalRequests();
          // this.$router.go();
        } else {
          this.terminalRequests = res.data;
        }
      } catch (error) {}
      this.isLoading = false;
    },
    clearFilterFunc() {
      this.filterByParams = "";
      this.fetchUnmappedTerminals();
      this.currentPage = 1;
      this.filterActive = false;
    },
    async downloadCSV() {
      try {
        this.isLoading = true;
        let source = this.source;
        let res = await this.$store.dispatch("DOWNLOAD_ALL_UNMAPPED_TERMINALS");
        this.isLoading = false;
        if (!res.status) {
          // window.location.open(res.data, "")
          alert(res.message);
        } else {
          window.open(res.data, "_blank");
        }
      } catch (error) {}
    },
    async downloadDatedCSV() {
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch(
            "DOWNLOAD_DATE_TERMINAL_REQUESTS_LOG_CSV",
            { date: this.dateData, source: this.source }
          );

          if (!res.status) {
            alert("Could not find any data within the date range");
            this.$router.go();
          } else {
            // console.log(res.data);
            this.isLoading = false;
            // this.alertDiv = true;
            window.open(res.data, "_blank");
          }
        } catch (error) {}
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const filterBy = this.filterByParams;
      let filterParams = "";
      if (filterBy === "terminalType") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "terminalProvider") {
        filterParams = this.filterByState;
      }
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_ALL_UNMAPPED_TERMINALS",
          {
            page: currPage - 1,
            filterBy,
            filterParams,
            search: this.search,
            searchActive: this.searchActive,
            filterActive: this.filterActive,
          }
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const filterBy = this.filterByParams;
      let filterParams = "";

      if (filterBy === "terminalType") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "terminalProvider") {
        filterParams = this.filterByTerminalProviders;
      }
      console.log(filterParams);
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_ALL_UNMAPPED_TERMINALS",
          {
            page: currPage + 1,
            filterBy,
            filterParams,
            search: this.search,
            searchActive: this.searchActive,
            filterActive: this.filterActive,
          }
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async handleSearch() {
      if (this.search !== "") {
        this.currentPage = 1;
        try {
          this.searchActive = true;
          this.isLoading = true;
          let res = await this.$store.dispatch("SEARCH_UNMAP_TERMINAL", {
            search: this.search,
          });
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Please enter a search parameter");
      }
    },
    async fetchDatedFilteredRecords() {
      // console.log(this.dateData.startdatetime > this.dateData.enddatetime);
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        this.dateFilter = true;
        this.currentPage = 1;

        try {
          let res = await this.$store.dispatch(
            "FETCH_DATED_TERMINAL_REQUESTS",
            { date: this.dateData, source: this.source }
          );

          if (res.data.item === null) {
            alert("Could not find any data within the date range");
            this.$router.go();
          } else {
          }
        } catch (error) {}
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
